import React, { useEffect, useState } from "react";
import "./myBookings.scss";
import { Link } from "react-router-dom";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import axios from "axios";
import { ApiBaseUrl } from "../../../../BaseUrl";

const MyBookings = () => {
  const [myBooking, setMybooking] = useState([]);
  const [loading, setLoading] = useState(true);
  const [paymentStatus, setPaymentStatus] = useState("");
  const PARENT_ID = localStorage.getItem("PARENT_ID");

  useEffect(() => {
    mybookingapi();
  }, []);

  const mybookingapi = () => {
    // Retrieve the access token from local storage
    const accessToken = localStorage.getItem("ACCESS_TOKEN");

    // Set the bearer token in the request headers
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    axios
      .get(`${ApiBaseUrl}/parent/${PARENT_ID}/mybookings`, config)
      .then((response) => {
        setMybooking(response.data.orders);
        setLoading(false);
        console.log("mybooking", response.data);
      });
  };

  return (
    <>
      <Header />
      <section style={{ paddingTop: "70px" }}>
        <div className="mybooking">
          <div className="mybooking-heading">
            <h4>My Bookings</h4>
          </div>
        </div>
        <div className="checkout_flex2">
          <div className="best-host-data">
            {myBooking.length === 0 ? (
              <p>No Bookings to Show</p>
            ) : myBooking && Array.isArray(myBooking) ? (
              myBooking.map((item) => {
                return (
                  <div className="best-host-inner" key={item.id}>
                    <div className="best_host_flex1">
                      <img
                        src={`${ApiBaseUrl}${item.host.profile_pic}`}
                        alt=""
                      />
                    </div>
                    <div className="best_host_flex2">
                      <h6>{item.host.name}</h6>
                      {/* <h6 className="role">{item.transaction.role}</h6> */}
                      {/* <h6>Amount pay: INR {item.transaction.amount} /-</h6> */}
                      {/* <h6>Transaction Id :{item.transaction.payment_id}</h6> */}
                      {/* <h6>{item.role}</h6> */}
                      <div className="timings">
                        <div className="start">
                          <p>
                            Drop-Off Date: <span>{item.start_date}</span>
                          </p>
                          <p>
                            Start Time: <span>{item.check_in_time}</span>
                          </p>
                          <p>
                            Phone: <span>{item.host.mobile}</span>
                          </p>
                        </div>
                        <div className="end">
                          <p>
                            Pickup Date: <span>{item.end_date}</span>
                          </p>
                          <p>
                            End Time: <span>{item.check_out_time}</span>
                          </p>
                          {/* <p>
                            Email: <span>{item.host.email}</span>
                          </p> */}
                        </div>
                      </div>
                      <p
                        style={{
                          margin: "0",
                          marginTop: "0.2rem",
                          fontSize: "14px",
                          width: "100%",
                        }}
                      >
                        {/* Payment Status: */}
                        {/* {item.status === 0 ? (
                          <span style={{ color: "red" }}>Pending</span>
                        ) : item.status === 1 ? (
                          <span style={{ color: "green" }}>Paid</span>
                        ) : item.status === 2 ? (
                          <span style={{ color: "red" }}>Paid</span>
                        ) : (
                          ""
                        )} */}
                      </p>
                      {/* <div className="payment_button">
                        {item.payment_status === 0 ? (
                          <Link
                            className="pay_btn"
                            to={item.short_url}
                          >
                            Pay Now
                          </Link>
                        ) : item.payment_status === 1 ? (
                          <Link
                            className="pay_btn"
                            to={item.short_url}
                          >
                            Pay Now
                          </Link>
                        ) : item.payment_status === 2 ? (
                          <p className="pay_btn paid">Paid</p>
                        ) : (
                          ""
                        )}
                      </div> */}
                      <div className="payment_button">
                       
                          <Link
                            className="order_btn"
                            to={`/final-order-summary/${item.id}`}
                          >
                            View Order
                          </Link>
                       
                          {/* <Link
                            className="pay_btn"
                            to={item.short_url}
                          >
                            Check-In
                          </Link> */}
                      
                      
                       
                      </div>
                      {/* <h6>Contact no.: {item.host.mobile}</h6> */}
                    </div>
                  </div>
                );
              })
            ) : (
              <h1></h1>
            )}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default MyBookings;
