import React from 'react'
import Header from '../../../components/header/Header'
import Footer from '../../../components/footer/Footer'

function WoofilyCommitments() {
  return (
    <>
      <Header/>
      <div style={{padding: "140px 110px"}}>
    <h1>Woofily's Commitment to Pets, Pet Parents, and Hosts: Upholding Compassion and Anti-Discrimination</h1>
    <p>At Woofily, we are committed to creating a community where every pet, pet parent, and host feels welcomed, valued, and respected. Our platform is built on a foundation of compassion, fairness, and respect for all. We stand firmly against discrimination and are dedicated to ensuring that every pet, regardless of breed or background, and every person, regardless of race, gender, or identity, is treated with care and equality. We align with the Prevention of Cruelty to Animals Act, 1960 and the Animal Welfare Board of India (AWBI) Guidelines to create a community that fosters kindness and protection for all.</p>

    <h3>For Pets: Compassionate Care Without Discrimination</h3>
    <p>At Woofily, pets are at the heart of our mission. We believe that every pet, regardless of breed, size, or health condition, deserves love and care. Following the principles outlined in the Prevention of Cruelty to Animals Act, 1960, we ensure that all pets are treated humanely and with the compassion they deserve.</p>
    <ul>
        <li><strong>Breed-Neutral Care:</strong> Woofily adheres to the AWBI Guidelines, which emphasize that discrimination based on breed is unacceptable. Every pet, regardless of whether they are a dog, cat, or another animal, receives equal care and attention. We encourage our hosts to embrace diversity among pets and to offer the same level of love and care to all.</li>
        <li><strong>Safe and Comfortable Spaces:</strong> Our platform ensures that pets are placed in homes where they will receive the proper care, safety, and comfort. We work closely with hosts to ensure that all pets are treated in accordance with the Prevention of Cruelty to Animals Act, 1960, ensuring that no pet is subjected to cruelty or neglect.</li>
    </ul>

    <h3>For Pet Parents: A Trusted and Inclusive Community</h3>
    <p>As a pet parent, your primary concern is the well-being of your furry family member. Woofily is dedicated to building a community that you can trust—one where discrimination has no place and where every pet parent is welcomed with open arms.</p>
    <ul>
        <li><strong>Equal Access for All:</strong> Following the AWBI Guidelines, Woofily ensures that no pet parent faces discrimination based on race, gender, religion, or any other factor. Every pet parent has the right to access our services, and we stand against any bias that may prevent them from doing so.</li>
        <li><strong>Transparency and Support:</strong> We offer clear and transparent communication between pet parents and hosts, ensuring that you feel confident in the care your pet is receiving. Woofily is committed to addressing any issues related to discrimination or improper treatment immediately, aligning with the values of the Prevention of Cruelty to Animals Act, 1960.</li>
    </ul>

    <h3>For Pet Hosts: Empowering You to Provide Compassionate Care</h3>
    <p>Woofily recognizes the dedication and hard work that pet hosts bring to our community. We are committed to supporting hosts by providing a platform that values their contributions and ensures that they are treated with respect. We also encourage hosts to follow the principles of fairness, kindness, and non-discrimination, as outlined in the AWBI Guidelines.</p>
    <ul>
        <li><strong>Inclusive Opportunities:</strong> Woofily ensures that every qualified host, regardless of background, has the opportunity to care for pets. We believe that diverse experiences among hosts enrich our community and provide pet parents with a wide range of care options. We also support hosts in creating an environment that aligns with the compassionate care mandated by the Prevention of Cruelty to Animals Act, 1960.</li>
        <li><strong>Education and Resources:</strong> Woofily provides educational tools and resources to help hosts care for pets according to best practices. We also provide training on how to avoid discriminatory behavior and ensure that all hosts are equipped to provide safe and loving care to pets of all breeds and backgrounds.</li>
    </ul>

    <h3>Woofily’s Community Guidelines: Creating a Discrimination-Free Space Together</h3>
    <p>At Woofily, we believe in building a community that is inclusive, compassionate, and respectful. We are committed to upholding the principles of the Animal Welfare Board of India (AWBI) Guidelines and the Prevention of Cruelty to Animals Act, 1960 to ensure that pets, pet parents, and hosts are treated with the dignity they deserve.</p>
    <ul>
        <li><strong>Zero Tolerance for Discrimination:</strong> Discrimination against pets, pet parents, or hosts based on breed, race, gender, religion, or any other characteristic is not tolerated on our platform. Woofily actively works to create an inclusive environment for all.</li>
        <li><strong>Compassionate Care for Pets:</strong> Following the Prevention of Cruelty to Animals Act, 1960, Woofily ensures that every pet is treated humanely and with care. We require our hosts to follow best practices in animal care and to provide a safe, comfortable space for every pet they host.</li>
        <li><strong>Supportive and Inclusive Community:</strong> Woofily fosters a supportive community where everyone feels welcome. We encourage open communication, education, and resources to help our community members provide the best care and support for pets, pet parents, and hosts.</li>
    </ul>

    <p>Together, we can create a community where discrimination has no place, and where every paw, every parent, and every host is celebrated. Join Woofily in building a safe, welcoming, and compassionate space for all.</p>
</div>

      <Footer/>
    </>
  )
}

export default WoofilyCommitments
