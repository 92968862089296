import React from "react";
import "./footer.scss";
import { Link } from "react-router-dom";
import { BsDot } from "react-icons/bs";
import { FaInstagram, FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

const Footer = () => {
  return (
    <>
      <section className="footer-section-wrapper">
        <div className="footer-top-section flex-center">
          <ul>
            <li>Woofily</li>
            <li>
              <a href="/about-woofily">Our Story</a>
            </li>
            <li>
              <a href="/woofily-commitments">Woofily Commitments</a>
            </li>
            <li>
              <a href="/">Trust & Safety at Woofily</a>
            </li>
            <li>
              <a href="/">Whats's New at Woofily</a>
            </li>
            <li>
              <a href="/blogs">Blog & Resources</a>
            </li>
            <li>
              <a href="/">Join Woofily !!</a>
            </li>
            <li>
              <a href="/">Press and Media</a>
            </li>
          </ul>

          <ul>
            <li>Pet Host</li>
            <li>
              <a href="/become-a-pet-host">Become a Pet Host</a>
            </li>
            <li>
              <a href="/become-a-verified-pet-host">
                How to Become a Verified Pet Host
              </a>
            </li>
            <li>
              <a href="/Woofily-host-dedicated-support">
                Host Dedicated Support
              </a>
            </li>
            <li>
              <a href="/pet-hosting-guidelines">Pet Hosting Guidelines</a>
            </li>
            <li>
              <a href="/safety-and-care-tips-for-pet-hosts">
                Safety and Care Tips for Pet Hosts
              </a>
            </li>
            <li>
              <a href="/host-terms-and-conditions">Host Terms & Conditions</a>
            </li>
            <li>
              <a href="/Woofily-host-FAQs">
                Frequently Asked Questions for Hosts
              </a>
            </li>
          </ul>
          <ul>
            <li>Pet Parent</li>
            <li>
              <Link to="/">Find a Trusted Pet Host</Link>
            </li>
            <li>
              <a href="/how-it-works-for-pet-parent">
                How It Works for Pet Parents
              </a>
            </li>
            <li>
              <a href="/pet-parent-support">Pet Parent Support</a>
            </li>
            <li>
              <a href="/preparing-your-pet-for-boarding">
                Preparing Your Pet for Boarding
              </a>
            </li>
            <li>
              <a href="/pet-care-tips-and-advice">Pet Care Tips and Advice</a>
            </li>
            <li>
              <a href="/Woofily-parent-FAQs">
                Frequently Asked Questions for Pet Parent
              </a>
            </li>
            <li>
              <a href="/pet-parent-privacy-policy">Pet Parent Privacy Policy</a>
            </li>
          </ul>
          <ul>
            <li>Community</li>
            <li>
              <a href="/join-the-Woofily-Host-Community">
                Join the Woofily Host Community
              </a>
            </li>
            <li>
              <a href="/">Pet Parent Stories</a>
            </li>
            <li>
              <a href="/">Pet Host Testimonials</a>
            </li>
            <li>
              <a href="/">Follow Us on Instagram</a>
            </li>
            <li>
              <a href="/">Pet Events & Partnerships</a>
            </li>
            <li>
              <a href="/">Referral Program</a>
            </li>
            <li>
              <a href="/">Woofily Newsletter</a>
            </li>
          </ul>
          <ul>
            <li> Woofily Support</li>
            <li>
              <Link to="/">Help Center</Link>
            </li>
            <li>
              <a href="/">Contact Support</a>
            </li>
            <li>
              <a href="/">Live Chat Support</a>
            </li>
            <li>
              <a href="/">Pet Parent Support Resources</a>
            </li>
            <li>
              <a href="/">Host Support Resources</a>
            </li>
            <li>
              <a href="/">Safety & Trust Policies</a>
            </li>
            <li>
              <a href="/">Report an Issue</a>
            </li>
          </ul>
        </div>
        <div className="mobile-extended-footer flex-center">
          <p className="privacy flex-center">
            <BsDot className="dot-icon" />
            <Link to="/privacy-and-policy">Privacy & Policy</Link>
          </p>
          <p className="terms flex-center">
            <BsDot className="dot-icon" />
            <Link to="/terms-and-conditions">Terms & Conditions</Link>
          </p>
        </div>
        <div className="footer-bottom-section flex-center">
          <div className="bottom-footer-left-section flex-center">
            <p className="copywright">© 2024 Woofily Private Limited</p>
            <p className="privacy flex-center">
              <BsDot className="dot-icon" />
              <Link to="/privacy-and-policy">Privacy & Policy</Link>
            </p>
            <p className="terms flex-center">
              <BsDot className="dot-icon" />
              <Link to="/terms-and-conditions">Terms & Conditions</Link>
            </p>
          </div>
          <div className="bottom-footer-right-section flex-center">
            <div className="social-icon instagram flex-center">
              <a
                href="https://www.instagram.com/woof.ily/?igsh=ZG10NTJmazBhem1l"
                target="blank"
              >
                <FaInstagram className="icon" />
              </a>
            </div>
            <div className="social-icon facebook flex-center">
              <a
                href="https://www.facebook.com/Woofily?mibextid=LQQJ4d"
                target="blank"
              >
                <FaFacebookF className="icon" />
              </a>
            </div>
            <div className="social-icon linkedin flex-center">
              <a
                href="https://www.linkedin.com/company/woof-ily/"
                target="blank"
              >
                <FaLinkedinIn className="icon" />
              </a>
            </div>
            {/* <div className="social-icon twitter flex-center">
              <a href="/">
                <FaXTwitter className="icon" />
              </a>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
