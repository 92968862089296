import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiBaseUrl } from "../../../BaseUrl";
import { message } from "antd";

// Action to Get Otp::
export const getOtpRequest = createAsyncThunk(
  "getOtpRequest",
  async (userInputData, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${ApiBaseUrl}/otp-send`,
        userInputData
      );

      if (response.data.status === "ok") {
        // Display success message
        message.success(response.data.message);
        return response.data;
      } else {
        // If there's some error in response but no error code
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      // Handle network or unexpected errors
      const errorMessage =
        error.response?.data?.message ||
        "Failed to send OTP. Please try again.";
      message.error(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

// Action to verify Otp::
export const verifyOtpRequest = createAsyncThunk(
  "verifyOtpRequest",
  async (userOtp, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${ApiBaseUrl}/otp-verify`, userOtp);
      if (response.data.error) {
        // Display error message using Ant Design's message component
        message.error(response.data.error.message);
        return rejectWithValue(response.data.error.message);
      }
      return response.data;
    } catch (error) {
      // Handle any other errors (like network issues, etc.)
      const errorMessage =
        error.response?.data?.error?.message ||
        "Something went wrong. Please try again.";
      message.error(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

// Action to logOut user::
export const logOut = createAsyncThunk("logOut", () => {
  localStorage.removeItem("isUserLoggedIn");
});

const loginSlice = createSlice({
  name: "login",
  initialState: {
    otpSending: false,
    otpSent: false,
    otpVerifying: false,
    otpVerified: false,
    isAuthenticated: false,
    userData: null,
    otpError: null,
    verificationError: null,
  },
  extraReducers: (builder) => {
    builder.addCase(getOtpRequest.pending, (state) => {
      state.otpSending = true;
    });
    builder.addCase(getOtpRequest.fulfilled, (state, action) => {
      state.otpSent = true;
    });
    builder.addCase(getOtpRequest.rejected, (state, action) => {
      state.otpSending = false;
      state.error = action.payload;
    });
    builder.addCase(verifyOtpRequest.pending, (state) => {
      state.otpVerifying = true;
    });
    builder.addCase(verifyOtpRequest.fulfilled, (state, action) => {
      state.otpVerified = true;
      state.isAuthenticated = true;
      state.userData = action.payload;
      localStorage.setItem("isUserLoggedIn", true);
      localStorage.setItem("ACCESS_TOKEN", state.userData.Jwt.access);
      localStorage.setItem("PARENT_ID", state.userData.client.id);
      localStorage.setItem("ROLE", state.userData.client.role);
      localStorage.setItem("PROFILE_PIC", state.userData.client.profile_pic);
      localStorage.setItem("USER_NAME", state.userData.client.name);
      localStorage.setItem("USER_EMAIL", state.userData.client.email);
    });
    builder.addCase(verifyOtpRequest.rejected, (state, action) => {
      state.otpVerifying = false;
      state.error = action.payload;
    });
  },
});

export default loginSlice.reducer;
