import React,{useEffect,useState,useRef} from 'react'
import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer'
import "./final-order-summary.scss"
import { useParams } from "react-router-dom";
import { ApiBaseUrl } from '../../../../BaseUrl';
import axios from 'axios';
import { MdDownload } from 'react-icons/md';
import html2pdf from 'html2pdf.js';
import Invoice from '../../../invoice-module/invoice';

function FinalOrderSummary() {
  const invoiceRef = useRef();
  const { order_id } = useParams();
  const [orderData, setOrderData] = useState(null); 
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null); 
  const accessToken = localStorage.getItem("ACCESS_TOKEN");
  useEffect(() => {
    const fetchOrderData = async () => {
      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
      try {
        const response = await axios.get(`${ApiBaseUrl}/Generate-Invoice/${order_id}`,config);
        setOrderData(response.data); 
        setLoading(false); 
      } catch (err) {
        setError("Failed to fetch order data"); 
        setLoading(false); 
      }
    };
    fetchOrderData(); 
  }, [order_id]);
  const downloadPdf = () => {
    const element = invoiceRef.current;
    const opt = {
      margin: 0,
      filename: `invoice-${order_id}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    };
    html2pdf().from(element).set(opt).save();
  };
  return (
    <>
    <Header />
    <section style={{ paddingTop: "100px",paddingBottom:"200px" }}>
    <div className='summary'>
    <div className="summary-heading">
            <h4> Order Summary</h4>
    </div>
    </div>
    <div className="download-invoice" >
          <button className="download-invoice-link" onClick={downloadPdf}>
            <MdDownload /> Download Invoice 
          </button>
    </div>
    
     {/* Hidden Invoice Component */}
     <div style={{ display: 'none' }}> {/* Hide the Invoice component in the UI */}
          <div ref={invoiceRef}> {/* Reference to the invoice content */}
            <Invoice orderData={orderData} />
          </div>
      </div>
    <div className="order-summary-details">
      {/* Pet Information */}
      <div className="section pet-info" >
      <div className="pet-info-grid">
      <div className="info-header">
        <h2>Pet Information</h2>
      </div>
      <div className="pet-details-row">
        <img src={ApiBaseUrl+orderData?.PetDetails?.pet_profile_img} alt="" className="pet-image" />
        <p><strong>Name:  {orderData?.PetDetails?.name}</strong> </p>
        <p><strong>Gender:  {orderData?.PetDetails?.gender}</strong> </p>
        <p><strong>Age:  {orderData?.PetDetails?.adoption_date}</strong> </p>
      </div>
      </div>
      </div>

      {/* Rating Section */}
      {/* <div className="section rating">
        <h2>How was the service?</h2>
        <div className="stars">
          {[...Array(5)].map((_, i) => (
            <span key={i} className={`star ${i < rating ? "filled" : ""}`}>
              ★
            </span>
          ))}
        </div>
      </div> */}

      {/* Host Information */}
      <div className="section host-info">
      <div className="host-info-grid">
      <div className="info-header">
        <h2>Host Information</h2>
      </div>
      <div className="host-details-row">
        <img src={ApiBaseUrl+orderData?.invoice?.host?.profile_pic} alt="" className="host-image" />
        <p><strong>Name:  {orderData?.invoice?.host?.name}</strong> </p>
        <p><strong>Gender:  {orderData?.invoice?.host?.gender}</strong> </p>
        <p><strong>Email:  {orderData?.invoice?.host?.email}</strong> </p>
      </div>
      <div>Address:  {orderData?.invoice?.host?.address1} {orderData?.invoice?.host?.address2} {orderData?.invoice?.host?.city}-
      {orderData?.invoice?.host?.state} {orderData?.invoice?.host?.pincode} 
      </div>
      </div>
      </div>

      {/* Order Details */}
      <div className="section order-details">
      <div className="order-detail-info-grid">
      <div className="info-header">
        <h2>Order Details</h2>
      </div>
      <div className="order-details-row">
        <p><strong>Order ID: </strong> {orderData?.invoice?.order_id?.id} </p>
        <p><strong>Created At: </strong> {orderData?.invoice?.order_id?.created_at} </p>
      </div>
      <div className="order-details-row">
        <p><strong>Checkin Time: </strong> {orderData?.invoice?.order_id?.check_in_time} </p>
        <p><strong>Checkout Time:</strong>  {orderData?.invoice?.order_id?.check_out_time} </p>
      </div>
      <div className="order-details-row">
        <p><strong>Amount:</strong>  {orderData?.invoice?.amount} </p>
        <p><strong> Additional Info: </strong> {orderData?.invoice?.order_id?.any_additional_information} </p>
      </div>
      </div>
      </div>
    </div>
    </section>
     <Footer />
     </>
  )
}

export default FinalOrderSummary
