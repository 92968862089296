import React from "react";
import "./becomeAPetHost.scss";
import Header from "../../../components/header/Header";
import Footer from "../../../components/footer/Footer";
import { FaPlus } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

const BecomeAPetHost = () => {
  const navigate = useNavigate();
  return (
    <>
      <Header />
      <section className="become-a-pet-host-with-woofily-wrapper">
        <div className="first-section-wrapper flex-center">
          <div className="info-container flex-center">
            <h1>
              Become a Pet Host
              <img
                src="/assets/images/footer-pages/pet-host/orange-paws.png"
                alt=""
              />
            </h1>
            <h1>with Woofily</h1>
            <p>
              Love pets? Turn your passion into a fun way to earn! Offer a cozy,
              safe space for pets while their owners are away. Join Woofily’s
              pack of trusted hosts and start connecting with pet parents who
              need reliable, loving care for their furry friends.
            </p>
          </div>
          <div className="media-container flex-center">
            <img
              src="/assets/images/footer-pages/pet-host/host-pets.png"
              alt=""
            />
            <div
              onClick={() => {
                navigate("/become-host");
              }}
              className="cta-btn flex-center"
            >
              <div className="cta-icon flex-center">
                <FaPlus className="icon" />
              </div>
              <div className="cta-info">
                <h3>Become a Host</h3>
                <p>Fill the form to become a host</p>
              </div>
            </div>
          </div>
        </div>

        <div className="second-section-wrapper flex-center">
          <div className="media-container flex-center">
            <img
              src="/assets/images/footer-pages/pet-host/cat-question.png"
              alt=""
            />
          </div>
          <div className="info-container flex-center">
            <h1>
              Why Become a Pet Host?
              <img
                src="/assets/images/footer-pages/pet-host/purple-paws.png"
                alt=""
              />
            </h1>
            <p>
              If your heart melts every time you see a wagging tail or a purring
              cat, becoming a Woofily pet host could be your dream side gig! Not
              only do you get to spend time with adorable pets, but you also
              earn extra cash while doing it. Whether you’re a lifelong animal
              lover or simply want to open your home to pets in your area,
              Woofily makes it easy to get started.
            </p>
            <p>
              Woofily connects pet parents with caring, trustworthy hosts like
              you! We’ve built a community where pet parents can confidently
              leave their pets, knowing they’re in safe and loving hands.
              Hosting through Woofily is all about creating tail-wagging
              experiences for pets and peace of mind for their owners!
            </p>
          </div>
        </div>

        <div className="third-section-wrapper flex-center">
          <div className="central-heading-container">
            <h1>
              What You Need To Become A Pet Host
              <img
                src="/assets/images/footer-pages/pet-host/red-paws.png"
                alt=""
              />
            </h1>
            <p>
              Joining Woofily is easy! Here’s what you’ll need to become a
              trusted pet host
            </p>
          </div>
          <div className="needs-info-cards-wrapper flex-center">
            <div className="left-cards-container">
              <div className="needs-card card-one">
                <div className="card-heading flex-center">
                  <img
                    src="/assets/images/footer-pages/pet-host/card-pet-friendly-space.png"
                    alt=""
                  />
                  <h3>A Pet-Friendly Space</h3>
                </div>
                <p>
                  You don’t need a mansion, but you should have a clean, safe,
                  and cozy area where pets can relax, play, and feel at home.
                </p>
              </div>
              <div className="needs-card card-two">
                <div className="card-heading flex-center">
                  <img
                    src="/assets/images/footer-pages/pet-host/card-availability.png"
                    alt=""
                  />
                  <h3>Availability & Flexibility</h3>
                </div>
                <p>
                  Hosts decide their own schedules, so having some availability
                  to care for pets is key. You can choose to host pets
                  occasionally or regularly, depending on what works for you.
                </p>
              </div>
              <div className="needs-card card-three">
                <div className="card-heading flex-center">
                  <img
                    src="/assets/images/footer-pages/pet-host/card-reliability.png"
                    alt=""
                  />
                  <h3>Reliability and Responsibility</h3>
                </div>
                <p>
                  As a Woofily host, you’ll be trusted with someone’s beloved
                  pet, so being reliable and responsible is a must. Showing up
                  on time and following through on promised services will help
                  build strong relationships with pet parents.
                </p>
              </div>
            </div>
            <div className="middle-cards-container">
              <div className="needs-card card-four">
                <img
                  className="hanging-cat"
                  src="/assets/images/footer-pages/pet-host/hanging-cat.png"
                  alt=""
                />
                <div className="card-heading flex-center">
                  <img
                    src="/assets/images/footer-pages/pet-host/card-love-pets.png"
                    alt=""
                  />
                  <h3>Love for Pets</h3>
                </div>
                <p>
                  The most important requirement is that you’re a true pet
                  lover! You should be excited about spending time with pets,
                  making them feel safe and happy, and giving them the care they
                  deserve.
                </p>
              </div>
              <div className="needs-card card-five">
                <div className="card-heading flex-center">
                  <img
                    src="/assets/images/footer-pages/pet-host/card-verification.png"
                    alt=""
                  />
                  <h3>Verification Process</h3>
                </div>
                <p>
                  For the safety of pets and their parents, you’ll need to
                  complete a quick verification process, which includes
                  background checks and verifying your hosting environment. This
                  ensures trust and safety for everyone involved.
                </p>
              </div>
            </div>
            <div className="right-cards-container">
              <div className="needs-card card-six">
                <div className="card-heading flex-center">
                  <img
                    src="/assets/images/footer-pages/pet-host/card-commitment.png"
                    alt=""
                  />
                  <h3>Commitment to Safety and Care</h3>
                </div>
                <p>
                  You’ll need to follow Woofily’s safety guidelines, including
                  providing proper care, attention, and a secure environment for
                  pets at all times.
                </p>
              </div>
              <div className="needs-card card-seven">
                <div className="card-heading flex-center">
                  <img
                    src="/assets/images/footer-pages/pet-host/card-clear-communication.png"
                    alt=""
                  />
                  <h3>Clear Communication Skills</h3>
                </div>
                <p>
                  Pet parents will appreciate clear and timely communication.
                  Whether it’s sharing updates about their pet’s stay or
                  answering questions before the booking, great communication
                  helps build trust.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="fourth-section-wrapper flex-center">
          <div className="info-container flex-center">
            <h1>
              What Does It Mean to
              <img
                src="/assets/images/footer-pages/pet-host/pink-paws.png"
                alt=""
              />
            </h1>
            <h1>Be a Woofily Pet Host?</h1>
            <p>
              As a Woofily pet host, you’ll create a happy, safe space for pets
              to stay when their owners are away. Whether it’s just for a day or
              a long vacation, you’ll be their cozy home away from home!
            </p>
            <p>
              You can choose the types of pets you’d like to host and the
              services you want to offer—whether it’s just a comfy bed for the
              night, daily walks, or even grooming. It’s your call! You’re the
              boss of your own hosting business.
            </p>
          </div>
          <div className="media-container flex-center">
            {/* <img
              src="/assets/images/footer-pages/pet-host/host-pets.png"
              alt=""
            /> */}
          </div>
        </div>

        <div className="fifth-section-wrapper flex-center">
          <div className="central-heading-container">
            <h1>
              The Benefits of Becoming a Pet Host
              <img
                src="/assets/images/footer-pages/pet-host/yellow-paws.png"
                alt=""
              />
            </h1>
            {/* <p>
              Joining Woofily is easy! Here’s what you’ll need to become a
              trusted pet host
            </p> */}
          </div>
          <div className="needs-info-cards-wrapper flex-center">
            <div className="left-cards-container">
              <div className="needs-card flex-center">
                <img
                  src="/assets/images/footer-pages/pet-host/card-cuddle.png"
                  alt=""
                />
                <div className="card-heading">
                  <h3>Earn with Cuddles</h3>
                  <p>
                    Get paid for hanging out with adorable pets! You set your
                    rates and enjoy flexible hours.
                  </p>
                </div>
              </div>
              <div className="needs-card flex-center">
                <img
                  src="/assets/images/footer-pages/pet-host/card-cuddle.png"
                  alt=""
                />
                <div className="card-heading">
                  <h3>Set Your Own Schedule</h3>
                  <p>
                    You decide when you’re available to host and how many furry
                    guests you can take. Hosting can fit around your
                    life—whether it’s a weekend gig or a full-time adventure.
                  </p>
                </div>
              </div>
            </div>
            <div className="right-cards-container">
              <div className="needs-card flex-center">
                <img
                  src="/assets/images/footer-pages/pet-host/card-schedule.png"
                  alt=""
                />
                <div className="card-heading">
                  <h3>Pet Parent Connections</h3>
                  <p>
                    Build amazing relationships with local pet parents who trust
                    you to care for their four-legged family members.
                  </p>
                </div>
              </div>
              <div className="needs-card flex-center">
                <img
                  src="/assets/images/footer-pages/pet-host/card-cuddle.png"
                  alt=""
                />
                <div className="card-heading">
                  <h3>Woofily Support</h3>
                  <p>
                    From resources to 24/7 customer service, we’ve got your back
                    every step of the way! Plus, our platform makes it super
                    easy to connect with pet parents.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="sixth-section-wrapper flex-center">
          <div className="central-heading-container">
            <h1>
              How to get Started
              <img
                src="/assets/images/footer-pages/pet-host/blue-paws.png"
                alt=""
              />
            </h1>
            <p>
              Becoming a Woofily pet host is quick, simple, and fun! Just follow
              these easy steps
            </p>
          </div>
          <div className="start-info-cards-wrapper flex-center">
            <div className="mobile-card flex-center left-card">
              <img
                src="/assets/images/footer-pages/pet-host/signup-mobile.png"
                alt=""
              />
              <h3>Sign Up</h3>
              <p>
                Create your host profile in minutes. Let pet parents know all
                about your pet care experience and why you’re the perfect fit
                for their fur babies!
              </p>
            </div>
            <img
              className="straight-dashed-arrow"
              src="/assets/images/footer-pages/pet-host/straight-dashed-arrow.png"
              alt=""
            />
            <div className="mobile-card flex-center middle-card">
              <img
                src="/assets/images/footer-pages/pet-host/verification-mobile.png"
                alt=""
              />
              <h3>Complete Your Verification</h3>
              <p>
                Safety is everything! We’ll guide you through our quick
                verification process so pet parents know they can trust you.
              </p>
            </div>
            <img
              className="straight-dashed-arrow"
              src="/assets/images/footer-pages/pet-host/straight-dashed-arrow.png"
              alt=""
            />
            <div className="mobile-card flex-center right-card">
              <img
                src="/assets/images/footer-pages/pet-host/mobile-rates.png"
                alt=""
              />
              <h3>Set Your Availability and Rates</h3>
              <p>
                Customize your hosting offers, from daycare to overnight stays,
                and set the rates that work best for you.
              </p>
            </div>
          </div>
          <img
            className="curved-dashed-arrow"
            src="/assets/images/footer-pages/pet-host/curved-dashed-arrow.png"
            alt=""
          />
          <div className="start-hosting-card flex-center">
            <h3>Start Hosting</h3>
            <p>
              Once you’re verified, you’ll be ready to welcome your first pet
              guests. It’s that easy!
            </p>
            <img
              src="/assets/images/footer-pages/pet-host/start-hosting.png"
              alt=""
            />
          </div>
        </div>

        <div className="seventh-section-wrapper flex-center">
          <div className="central-heading-container">
            <h1>
              What Services Can You Offer?
              <img
                src="/assets/images/footer-pages/pet-host/orange-paws.png"
                alt=""
              />
            </h1>
            <p>
              As a Woofily host, you can customize your services to fit your
              skills and interests
            </p>
          </div>
          <div className="services-wrapper flex-center">
            <div className="service-container">
              <div className="service-image">
                <img src="" alt="" />
              </div>
              <div className="service">
                <h3>Pet Boarding</h3>
                <p>
                  Provide a comfy, fun home for pets while their owners are
                  away.
                </p>
              </div>
            </div>
            <div className="service-container">
              <div className="service-image">
                <img src="" alt="" />
              </div>
              <div className="service">
                <h3>Daycare</h3>
                <p>
                  Offer a playful, safe environment for pets who need care
                  during the day.
                </p>
              </div>
            </div>
            <div className="service-container">
              <div className="service-image">
                <img src="" alt="" />
              </div>
              <div className="service">
                <h3>Pet Walking</h3>
                <p>
                  Take dogs on fun adventures and keep them happy and active.
                </p>
              </div>
            </div>
            <div className="service-container">
              <div className="service-image">
                <img src="" alt="" />
              </div>
              <div className="service">
                <h3>Grooming</h3>
                <p>
                  If you’ve got grooming skills, add it to your service menu!
                </p>
              </div>
            </div>
            <div className="service-container">
              <div className="service-image">
                <img src="" alt="" />
              </div>
              <div className="service">
                <h3>Special Needs Care</h3>
                <p>
                  Have experience with elderly pets or those with special needs?
                  You can offer specialized care for pets who need extra love
                  and attention.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="eigth-section-wrapper flex-center">
          <div className="central-heading-container">
            <h1>
              Host Testimonials
              <img
                src="/assets/images/footer-pages/pet-host/testimonial-paws.png"
                alt=""
              />
            </h1>
            {/* <p>
              As a Woofily host, you can customize your services to fit your
              skills and interests
            </p> */}
          </div>
          <div className="testimonial-wrapper flex-center">
            <div className="testimonial">
              <div className="profile-wrapper flex-center">
                <div className="profile-img">
                  <img
                    src="/assets/images/female-dummy-profile.jpeg"
                    alt=""
                  />
                </div>
                <div className="profile-name">
                  <h3>Sana Chowdhary</h3>
                  <p>Pet Parent</p>
                </div>
              </div>
              <div className="review">
                <p>
                  "For all sceptical/worried dog owners Woofily has got your
                  back! Impressed by their passion and professionalism towards
                  giving your dog the best care possible in your absence! I
                  would highly recommend them!"
                </p>
              </div>
            </div>
            <div className="testimonial">
              <div className="profile-wrapper flex-center">
                <div className="profile-img">
                  <img
                    src="/assets/images/male-dummy-profile.png"
                    alt=""
                  />
                </div>
                <div className="profile-name">
                  <h3>Bharat Virmani</h3>
                  <p>Pet Parent</p>
                </div>
              </div>
              <div className="review">
                <p>
                  "Great Experience boarding my dog Ollie with Manmi from
                  Woofily. He was well taken care of and felt comfortable. Plus
                  their team was really helpful in finding the right boarder
                  quickly."
                </p>
              </div>
            </div>
            <div className="testimonial">
              <div className="profile-wrapper flex-center">
                <div className="profile-img">
                  <img
                    src="/assets/images/female-dummy-profile.jpeg"
                    alt=""
                  />
                </div>
                <div className="profile-name">
                  <h3>Pooja Aggarwal</h3>
                  <p>Pet Parent</p>
                </div>
              </div>
              <p className="review">
                "We have left our pup in their care many times. Woofily has been
                the best doggie daycare for Pluto so far. He always comes back
                home well fed and disease free, unlike the other pet boarding
                facilities. Highly recommended"
              </p>
            </div>
          </div>
        </div>

        <div className="ninth-section-wrapper">
          <div className="seventh-section-container flex-center">
            <div className="info-container">
              <h1>
                Start Earning
                <img
                  src="/assets/images/footer-pages/pet-host/outlined-paws.png"
                  alt=""
                />
              </h1>
              <h1>Today!</h1>
              <p>
                Sign up now and create your Woofily pet host profile. We can’t
                wait to welcome you to the pack!
              </p>
            </div>
            <div className="media-container">
              <img
                src="/assets/images/footer-pages/pet-host/rich-dog.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default BecomeAPetHost;