import React from 'react'
import Header from '../../../components/header/Header'
import Footer from '../../../components/footer/Footer'

function BecomeVerifiedHost() {
  return (
    <>
    <Header/>
    <div  style={{padding: "140px 110px"}}>
    <h2>How to Become a Verified Pet Host with Woofily</h2>
    <p>Ready to start hosting pets with Woofily? Become a verified pet host and gain the trust of pet parents who are looking for safe, reliable care for their furry friends. Here’s how you can complete the verification process and start welcoming pets into your home!</p>
    
    <h3>Why Verification Matters</h3>
    <p>At Woofily, safety and trust are our top priorities. Pet parents want to know their pets are in good hands, and the verification process is designed to ensure that every host meets our high standards for care and safety. By becoming a verified host, you’ll boost your credibility, attract more bookings, and give pet parents the peace of mind they need when choosing a host for their pets.</p>
    
    <h3>Steps to Become a Verified Pet Host</h3>
    
    <h4>1. Create Your Woofily Host Profile</h4>
    <p>The first step to becoming a verified pet host is creating your profile. It only takes a few minutes! Share a little bit about yourself, your experience with pets, and the types of animals you’re comfortable hosting. Don’t forget to mention any special skills you have, like grooming or handling pets with special needs. The more details you provide, the more trust you’ll build with potential clients.</p>
    
    <h4>2. Complete the Host Application</h4>
    <p>Once your profile is set up, you’ll need to complete the host application. This helps us gather important information about your hosting environment and the services you offer. You’ll be asked to provide details about your home setup, your availability, and the kind of care you plan to provide for pets.</p>
    
    <h4>3. Background Check</h4>
    <p>For the safety of pets and their owners, we require every host to complete a basic background check. This step ensures that pet parents can trust the hosts they choose, knowing they’ve been vetted for trustworthiness and reliability. Our background check is quick, secure, and handled by a trusted third-party provider.</p>
    
    <h4>4. Home Verification</h4>
    <p>Next, Woofily will verify your hosting environment. This step ensures that the space you provide for pets is safe, clean, and comfortable. Depending on your location, this may involve submitting photos of your home or scheduling a quick virtual home tour. We’ll check for pet-friendly spaces, secure areas (like fenced-in yards), and a welcoming atmosphere for your furry guests.</p>
    
    <h4>5. Set Up Payment Information</h4>
    <p>To get paid for your hosting services, you’ll need to set up your payment information on Woofily. We offer secure payment options to ensure you receive timely and hassle-free payments after each successful booking. Simply link your bank account or preferred payment method, and you’ll be ready to start earning!</p>
    
    <h4>6. Approval and Badge of Verification</h4>
    <p>Once you’ve completed the steps above, your profile will be reviewed by our team. Upon approval, you’ll receive a badge of verification on your Woofily profile, signaling to pet parents that you’ve passed all the necessary checks and are ready to safely host pets. Verified hosts tend to get more bookings, as pet parents feel confident leaving their pets with someone who’s been thoroughly vetted.</p>
    
    <h3>Why Become a Verified Host?</h3>
    <ul>
        <li><strong>Gain Pet Parents' Trust:</strong> Verified hosts are more likely to attract bookings because pet parents feel safer knowing their host has been vetted and approved.</li>
        <li><strong>Stand Out from the Crowd:</strong> Your verification badge helps your profile stand out, showing potential clients that you’re serious about providing top-quality care.</li>
        <li><strong>More Opportunities for Bookings:</strong> Verified hosts are prioritized in search results, meaning you’ll be more visible to pet parents looking for care in your area.</li>
        <li><strong>Enjoy Full Support from Woofily:</strong> As a verified host, you’ll have access to 24/7 support, resources, and tips to help you provide the best possible care to your furry guests.</li>
    </ul>
    
    <h3>Get Started Today!</h3>
    <p>Becoming a verified Woofily pet host is a simple, straightforward process designed to ensure safety, trust, and success for both hosts and pet parents. Ready to take the next step? Complete your verification and start hosting happy pets in no time!</p>
    
    <p><strong>Start your verification process today and join Woofily’s trusted community of pet hosts!</strong></p>
</div>

    <Footer/>
      
    </>
  )
}

export default BecomeVerifiedHost
