import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiBaseUrl } from "../../../BaseUrl";
let userCoordinates = JSON.parse(localStorage.getItem("userCoordinates"));
// Set default coordinates if userCoordinates is null or undefined
if (
  !userCoordinates ||
  typeof userCoordinates.lat === "undefined" ||
  typeof userCoordinates.lng === "undefined"
) {
  userCoordinates = { lat: 28.4594965, lng: 77.0266383 }; // Replace with your default coordinates
}
const { lat, lng } = userCoordinates;

// Action to Fectch List of Best Hosts::
export const fetchBestHosts = createAsyncThunk("fetchBestHosts", async () => {
  // alert(longitude)
  const response = await axios.get(
    `${ApiBaseUrl}/best-host?latitude=${lat}&longitude=${lng}`
  );
  return response.data;
});

// Action to Fectch List of Most Popular Hosts::
export const fetchMostPopularHosts = createAsyncThunk(
  "fetchMostPopularHosts",
  async () => {
    const response = await axios.get(
      `${ApiBaseUrl}/popular-host?latitude=${lat}&longitude=${lng}`
    );
    return response.data;
  }
);

// Action to Fectch List of Near By Hosts::
export const fetchNearByHosts = createAsyncThunk(
  "fetchNearByHosts",
  async () => {
    const response = await axios.get(
      `${ApiBaseUrl}/near-by-host?latitude=${lat}&longitude=${lng}`
    );
    return response.data;
  }
);

// Action to Fectch List of Hosts By Price::
export const fetchHostsByPrice = createAsyncThunk(
  "fetchHostsByPrice",
  async () => {
    const response = await axios.get(
      `${ApiBaseUrl}/low-to-high-price-host?latitude=${lat}&longitude=${lng}`
    );
    return response.data;
  }
);

// Action to Fectch Single Host Data By Id::
export const fetchHostById = createAsyncThunk(
  "fetchHostById",
  async (hostId) => {
    const response = await axios.get(`${ApiBaseUrl}/best-host/${hostId}`);
    return response.data;
  }
);

const homeHostsSlice = createSlice({
  name: "homeHosts",
  initialState: {
    isLoading: false,
    popularHosts: null,
    bestHosts: null,
    nearByHosts: null,
    hostsByPrice: null,
    hostById: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBestHosts.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchBestHosts.fulfilled, (state, action) => {
      state.isLoading = false;
      state.bestHosts = action.payload;
    });
    builder.addCase(fetchBestHosts.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
    builder.addCase(fetchMostPopularHosts.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchMostPopularHosts.fulfilled, (state, action) => {
      state.isLoading = false;
      state.popularHosts = action.payload;
    });
    builder.addCase(fetchMostPopularHosts.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
    builder.addCase(fetchNearByHosts.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchNearByHosts.fulfilled, (state, action) => {
      state.isLoading = false;
      state.nearByHosts = action.payload;
    });
    builder.addCase(fetchNearByHosts.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
    builder.addCase(fetchHostsByPrice.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchHostsByPrice.fulfilled, (state, action) => {
      state.isLoading = false;
      state.hostsByPrice = action.payload;
    });
    builder.addCase(fetchHostsByPrice.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
    builder.addCase(fetchHostById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchHostById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.hostById = action.payload;
    });
    builder.addCase(fetchHostById.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export default homeHostsSlice.reducer;