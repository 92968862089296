import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./../dashboard/dashBoard.scss";
import CancelIcon from "@mui/icons-material/Cancel";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { ApiBaseUrl } from "../../../../BaseUrl";
import { message } from "antd";

const HostAvailbilty = () => {
  const [selectedDates, setSelectedDates] = useState([]);
  const HOST_ID = localStorage.getItem("PARENT_ID");
  const [update, setUpdate] = useState(true);

  // Fetch available dates from the API
  useEffect(() => {
    const accessToken = localStorage.getItem("ACCESS_TOKEN");
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    axios
      .get(`${ApiBaseUrl}/calenderview/${HOST_ID}`, config)
      .then((response) => {
        const availableDaysString = response.data.available_days;
        const availableDates = availableDaysString
          .split(",")
          .map((date) => new Date(date).toDateString());

        setSelectedDates(availableDates); // Set the initially available dates
      })
      .catch((error) => {
        console.error("Error fetching available dates:", error);
        toast.error("Failed to load available dates.");
      });
  }, [HOST_ID]);

  // const toggleDate = (date) => {
  //   if (date instanceof Date && !isNaN(date.getTime())) {
  //     const formattedDate = date.toDateString();
  //     if (selectedDates.includes(formattedDate)) {
  //       setSelectedDates(selectedDates.filter((d) => d !== formattedDate));
  //     } else {
  //       setSelectedDates([...selectedDates, formattedDate]);
  //     }
  //   }
  // };

  const toggleDate = (date) => {
    if (date instanceof Date && !isNaN(date.getTime())) {
      const formattedDate = date.toDateString();
      if (selectedDates.includes(formattedDate)) {
        setSelectedDates(selectedDates.filter((d) => d !== formattedDate));
        setUpdate(false);
      } else {
        setSelectedDates([...selectedDates, formattedDate]);
        setUpdate(false); // Set update to true when a new date is added
      }
    }
  };

  const isDateHighlighted = (date) => {
    return selectedDates.includes(date.toDateString());
  };

  const handleSetAvailability = () => {
    const accessToken = localStorage.getItem("ACCESS_TOKEN");
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const formattedDates = selectedDates
      .map((date) => {
        const d = new Date(date);
        if (isNaN(d.getTime())) {
          return null;
        }
        const utcDate = new Date(
          Date.UTC(d.getFullYear(), d.getMonth(), d.getDate())
        );
        return utcDate.toISOString().split("T")[0];
      })
      .filter((date) => date !== null);
    if (formattedDates.length === 0) {
      toast.error("Please select at least one valid date.");
      return;
    }
    const payload = {
      available_days: formattedDates.join(","),
    };
    axios
      .post(`${ApiBaseUrl}/calenderview/${HOST_ID}`, payload, config)
      .then((response) => {
        setUpdate(true);
        message.config({
          top: 70,
          fontSize: 16,
        });
        message.success("Availability update successfully!");
        // toast.success("Availability updated successfully!", {
        //   position: "top-left",   // Center the toast
        //   autoClose: 1500,          // Automatically close after 1.5 seconds
        //   hideProgressBar: true,    // Hide progress bar
        //   closeOnClick: true,
        //   pauseOnHover: false,
        //   draggable: false,
        // });
      })
      .catch((error) => {
        console.error("Error updating availability:", error);
        toast.error("Failed to update availability.");
      });
  };

  return (
    <>
      <ToastContainer />
      <div className="adjustavailabilty">
        <div className="adjustcontainer">
          <h5>Adjust your availability</h5>
          <h6>You can plan when you are available to host pets</h6>
          <div className="calneder">
            <div className="calender_main">
              <DatePicker
                dateFormat="dd-MM-yyyy"
                placeholderText="Select dates..."
                calendarClassName="date-picker-calendar"
                inline
                className="react-datepicker"
                highlightDates={selectedDates
                  .map((date) => new Date(date))
                  .filter((date) => !isNaN(date.getTime()))}
                dayClassName={(date) =>
                  isDateHighlighted(date) ? "highlighted-date" : undefined
                }
                onChange={toggleDate}
              />
              <div className="update-availability-button">
                <button disabled={update} onClick={handleSetAvailability}>
                  {selectedDates === null ? "Set" : "Update"} Availability
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <style jsx>{`
        .highlighted-date {
          background-color: yellow !important;
          color: black !important;
        }
      `}</style>
    </>
  );
};

export default HostAvailbilty;
